import { Icons } from '@/shared/ui';

interface ZoomControlsProps {
  toggleFullSize: () => void;
  fullSized?: boolean;
}

const ZoomControls = ({ toggleFullSize, fullSized = false }: ZoomControlsProps) => {
  return (
    <button
      aria-label="увеличить уменьшить"
      onClick={toggleFullSize}
      className="absolute right-4 top-4 z-40"
    >
      {fullSized ? (
        <Icons.LoupeMinus width={56} height={56} className="text-white" />
      ) : (
        <Icons.LoupePlus width={56} height={56} className="text-white" />
      )}
    </button>
  );
};

export default ZoomControls;
