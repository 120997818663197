import { useState } from 'react';

import useIsMobile from './useIsMobile';

export default function useImageNavigation() {
  const isMobile = useIsMobile();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageCurrentIndex = (index: number) => {
    setCurrentImageIndex(index);
  };

  return {
    handleImageCurrentIndex,
    currentImageIndex,
    isMobile
  };
}
