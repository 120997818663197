import { useState, useEffect } from 'react';

export default function useImageErrors(pictures: string[]) {
  const [imageErrors, setImageErrors] = useState<boolean[]>([]);

  useEffect(() => {
    setImageErrors(Array(pictures.length).fill(false));
  }, [pictures]);

  const handleImageError = (index: number) => {
    setImageErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  return {
    imageErrors,
    handleImageError
  };
}
