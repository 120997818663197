'use client';

import { useEffect, useState } from 'react';

import { getImageByLink } from '@/shared/api/media';

export default function useProcessImagesFromLinks(imagesStringArray: string[], key: string) {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const imagesGet = async () => {
      const imageLinks: string[] = await Promise.all(
        imagesStringArray.map(async (picture) => {
          const imageLinkResponse = await getImageByLink({
            bucketName: 'pets-sale-bucket',
            imageName: picture,
            subPath: key
          });
          const imgLink = await imageLinkResponse?.json();
          return imgLink?.imageUrl;
        })
      );
      setImages(imageLinks);
    };
    imagesGet();
  }, [imagesStringArray, key]);

  return images;
}
