import Link from 'next/link';
import React from 'react';

import { CarouselIndicators } from '@/entities/image-carousel';
import { useProcessImagesFromLinks } from '@/entities/publication-view';
import { useImageErrors, useImageNavigation } from '@/shared/lib/hooks';
import { GetPublicationsReturnItemType } from '@/shared/types';
import { ShareButton } from '@/shared/ui';

import PublicationCardInfo from './PublicationCardInfo';
import PublicationImageCarousel from './PublicationImageCarousel';

import 'swiper/css';

interface PublicationCardProps {
  publication: GetPublicationsReturnItemType;
  currentUserEmail: string | null | undefined;
}

const pubTypeBgColorLookup = {
  продажа: 'bg-chips-green',
  дарение: 'bg-chips-orange',
  случка: 'bg-chips-red'
};

export function PublicationCard({ publication, currentUserEmail }: PublicationCardProps) {
  const images = useProcessImagesFromLinks(publication.pictures, publication.id);
  const { currentImageIndex, isMobile, handleImageCurrentIndex } = useImageNavigation();
  const { imageErrors, handleImageError } = useImageErrors(images);

  const pet = publication?.pet;
  const currentAddress = pet?.owner?.addresses.find((address) => address.current);

  return (
    <div className="flex h-full flex-col rounded-3xl bg-white dark:bg-slate-800 md:rounded-4xl">
      <Link
        as={`/publications/${publication.id}`}
        className="relative flex flex-col"
        href="/publications/[slug]"
      >
        <PublicationImageCarousel
          pictures={images}
          publicationType={publication.publicationType as 'продажа' | 'дарение' | 'случка'}
          pubTypeBgColorLookup={pubTypeBgColorLookup}
          currentImageIndex={currentImageIndex}
          handleImageCurrentIndex={handleImageCurrentIndex}
          onImageError={handleImageError}
          imageErrors={imageErrors}
        />
        <CarouselIndicators currentIndex={currentImageIndex} length={images.length} />
        <span className="absolute inset-y-[79%] right-2 z-40 md:inset-y-[85%] md:right-5">
          <ShareButton
            paramLink={publication.id}
            className="h-[38px] w-[38px] rounded-full bg-white dark:bg-slate-800 md:h-[48px] md:w-[48px]"
          />
        </span>
      </Link>

      <article className="px-2 pb-2 text-start md:space-y-2 md:px-5 md:pb-5">
        <Link as={`/publications/${publication.id}`} href="/publications/[slug]">
          <PublicationCardInfo
            publication={publication}
            pet={pet}
            isMobile={isMobile}
            currentAddress={currentAddress}
            currentUserEmail={currentUserEmail}
          />
        </Link>
      </article>
    </div>
  );
}
