import { Location } from '@prisma/client';
import { addYears } from 'date-fns';
import { upperFirst } from 'lodash-es';
import { WithContext, Product, BreadcrumbList, Place } from 'schema-dts';

import { checkEnvironment } from '@/shared/lib';
import { Publication } from '@/shared/types';

interface GenerateJsonLdParams {
  publication: Publication;
  currentAddress?: Location;
}

export function generateJsonLd({ publication, currentAddress }: GenerateJsonLdParams): {
  mainJsonLd: WithContext<Product>;
  breadcrumbJsonLd: WithContext<BreadcrumbList>;
  placeJsonLd: WithContext<Place>;
} {
  const definedPetKind =
    publication?.animalType === 'dog'
      ? publication?.pet?.sex === 'мужской'
        ? 'кобеля'
        : 'суки'
      : publication?.pet?.sex === 'мужской'
        ? 'кота'
        : 'кошки';
  const definedPublicationType = publication?.publicationType;

  // **1. Основной JSON-LD с типом Product**
  const mainJsonLd: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: publication?.title,
    image: publication?.pictures,
    description: publication?.description,
    color: publication?.pet?.color ?? '',
    productID: publication?.id,
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   // TODO: Add rating for the pet
    //   ratingCount: 15,
    //   ratingValue: 5
    // },
    url: checkEnvironment().concat(`/publications/${publication?.id}`),
    offers: {
      '@type': 'Offer',
      priceValidUntil: addYears(publication?.createAt ?? new Date(), 1).toISOString(),
      image: publication?.pictures[0], // Первый элемент массива изображений
      hasMerchantReturnPolicy: {
        '@type': 'MerchantReturnPolicy',
        returnFees: 'https://schema.org/ReturnFeesCustomerResponsibility',
        returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
        merchantReturnDays: 60,
        returnMethod: 'https://schema.org/ReturnByMail',
        returnPolicyCountry: 'BY',
        applicableCountry: 'BY'
      },
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: 0,
          currency: 'BYN'
        },
        shippingDestination: {
          '@type': 'DefinedRegion',
          addressCountry: 'BY'
        },
        deliveryTime: {
          '@type': 'ShippingDeliveryTime',
          handlingTime: {
            '@type': 'QuantitativeValue',
            minValue: 0,
            maxValue: 4,
            unitCode: 'DAY'
          },
          transitTime: {
            '@type': 'QuantitativeValue',
            minValue: 5,
            maxValue: 30,
            unitCode: 'DAY'
          }
        }
      },
      seller: {
        '@type': 'Organization',
        name: publication?.author?.name ?? '',
        description: publication?.author?.bio ?? '',
        email: publication?.author?.email ?? '',
        address: currentAddress?.country
          ? {
              '@type': 'PostalAddress',
              addressLocality: currentAddress?.city ?? '',
              addressRegion: currentAddress?.region ?? '',
              addressCountry: currentAddress?.country ?? '',
              streetAddress: `${currentAddress?.street ?? ''}, ${currentAddress?.house ?? ''}`
            }
          : undefined
      },
      category: [
        `${upperFirst(definedPublicationType)} ${definedPetKind}`,
        publication?.pet?.breed ?? ''
      ],
      url: `https://www.lapki-pets.by/publications/${publication?.id}`,
      priceCurrency: publication?.currency,
      price: publication?.price,
      itemCondition: 'https://schema.org/UsedCondition',
      availability:
        publication?.availability === true
          ? 'https://schema.org/InStock'
          : 'https://schema.org/OutOfStock'
    }
  };

  // **2. JSON-LD для хлебных крошек с типом BreadcrumbList**
  const breadcrumbJsonLd: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Публикации',
        item: 'https://www.lapki-pets.by/publications'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: publication?.title,
        item: `https://www.lapki-pets.by/publications/${publication?.id}`
      }
    ]
  };

  // **3. JSON-LD с типом Place**
  const placeJsonLd: WithContext<Place> = {
    '@context': 'https://schema.org',
    '@type': 'Place',
    name: 'Lapki Pets',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Минск',
      addressRegion: 'Минская область',
      addressCountry: 'Республика Беларусь'
    }
  };

  return {
    mainJsonLd,
    breadcrumbJsonLd,
    placeJsonLd
  };
}
