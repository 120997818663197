import { useRef } from 'react';
import { SwiperRef } from 'swiper/react';

/**
 * Custom hook to manage Swiper navigation.
 * Provides a ref for the Swiper instance and navigation handlers.
 *
 * @returns An object containing the Swiper ref and navigation functions.
 */
export default function useImageSwiperNavigation() {
  const swiperRef = useRef<SwiperRef | null>(null);

  /**
   * Navigates to the previous slide.
   */
  const handlePrev = () => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  };

  /**
   * Navigates to the next slide.
   */
  const handleNext = () => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  };

  /**
   * Navigates to a specific slide by index.
   *
   * @param index - The index of the slide to navigate to.
   */
  const slideTo = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return {
    swiperRef,
    handlePrev,
    handleNext,
    slideTo
  };
}
