import { Location } from '@prisma/client';
import React from 'react';

import { GetPublicationsReturnItemType } from '@/shared/types';
import { Tooltip } from '@/shared/ui';

interface PublicationInfoProps {
  publication: GetPublicationsReturnItemType;
  pet: GetPublicationsReturnItemType['pet'];
  isMobile: boolean;
  currentAddress: Location | undefined;
  currentUserEmail: string | null | undefined;
}

const PublicationInfo: React.FC<PublicationInfoProps> = ({
  publication,
  pet,
  isMobile,
  currentAddress,
  currentUserEmail
}) => {
  return (
    <>
      <div className="flex justify-between pt-2">
        <div className="inline-flex flex-wrap lg:flex-nowrap">
          <p className="truncate text-xl font-bold text-blue-main dark:text-teal-300 md:text-3xl">
            {publication.price} {publication.currency}&nbsp;
          </p>
          <p className="content-center text-base font-bold text-blue-main dark:text-teal-300 md:text-sm">
            {publication.isBargainingAppropriate ? 'Торг' : ''}
          </p>
        </div>
      </div>
      {pet.breed.length > 20 && !isMobile ? (
        <Tooltip alignment="left" text={pet.breed}>
          <p className="truncate whitespace-nowrap text-base font-bold text-letters-black dark:text-white md:text-2xl">
            {pet.breed === 'Другое' ? 'Без породы' : pet.breed}
          </p>
        </Tooltip>
      ) : (
        <p className="line-clamp-2 text-sm font-bold text-letters-black dark:text-white md:truncate md:whitespace-nowrap md:text-2xl">
          {pet.breed === 'Другое' ? 'Без породы' : pet.breed}
        </p>
      )}
      <p className="text-base text-letters-black dark:text-white md:text-xl">
        {pet.sex === 'мужской' ? 'Мальчик' : 'Девочка'}
      </p>
      <p className="line-clamp-2 pt-2 text-sm text-letters-black dark:text-white md:text-xl">
        {publication.description}
      </p>
      <p className="mt:mt-0 mt-1 text-xs md:text-base">
        {currentAddress ? null : currentUserEmail === publication.author?.email ? (
          <b className="text-xs font-bold text-red-600">
            Укажите адрес в профиле. Это повысит шансы в поиске.
          </b>
        ) : null}
      </p>
    </>
  );
};

export default PublicationInfo;
