import { classNames } from '@/shared/lib';

interface CarouselIndicatorsProps {
  currentIndex: number;
  length: number;
}

export default function CarouselIndicators({ currentIndex, length }: CarouselIndicatorsProps) {
  return (
    <div className="flex h-4 w-full items-center justify-center gap-2 py-3">
      {new Array(length).fill(0).map((_, idx) => (
        <div
          key={idx}
          className={classNames(
            idx === currentIndex ? 'h-2 w-2 bg-slate-400' : 'h-1 w-1 bg-slate-500',
            'rounded-full bg-gray-500 dark:bg-white'
          )}
        />
      ))}
    </div>
  );
}
