import { Icons } from '@/shared/ui';

/* eslint-disable no-unused-vars */
interface CarouselControlsProps {
  goToPreviousImage: (e: React.MouseEvent<HTMLButtonElement>) => void;
  goToNextImage: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function CarouselControls({
  goToPreviousImage,
  goToNextImage
}: CarouselControlsProps) {
  return (
    <>
      <button
        type="button"
        className="group absolute start-0 top-0 z-30 hidden h-full cursor-pointer items-center justify-center px-2 focus:outline-none md:flex"
        onClick={goToPreviousImage}
      >
        <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-black/60 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:group-hover:bg-gray-300/60 dark:group-focus:ring-gray-300/70 md:h-10 md:w-10">
          <Icons.LeftButtonArrow className="h-4 w-4 text-white dark:text-gray-300 rtl:rotate-180" />
          <span className="sr-only">Предыдущая</span>
        </span>
      </button>

      <button
        type="button"
        className="group absolute end-0 top-0 z-30 hidden h-full cursor-pointer items-center justify-center px-2 focus:outline-none md:flex"
        onClick={goToNextImage}
      >
        <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-black/60 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:group-hover:bg-gray-300/60 dark:group-focus:ring-gray-300/70 md:h-10 md:w-10">
          <Icons.RightButtonArrow className="h-4 w-4 text-white dark:text-gray-300 rtl:rotate-180" />
        </span>
      </button>
    </>
  );
}
