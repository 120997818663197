'use client';

import { useEffect, useState } from 'react';

export default function useIsMobile() {
  // Mobile prioritized.
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Run the function on component mount to set the initial state
    handleResize();

    // Add event listener to listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
