import { useState } from 'react';

function useImageViewToggle() {
  const [fullSized, setFullSized] = useState(false);

  const toggleFullSize = () => {
    setFullSized((prev) => !prev);
  };

  return { fullSized, toggleFullSize };
}

export default useImageViewToggle;
